.c1ltgu9w{position:absolute;top:0;bottom:0;left:0;right:0;width:100%;height:100%;}.c1ltgu9w .youtube-video-iframe{width:100%;height:100%;}.c1ltgu9w .video-atom.video-paused{pointer-events:none;}.c1ltgu9w.video-hidden .video-item{visibility:hidden;pointer-events:none;}.c1ltgu9w.youtube-video-container--hover video{opacity:0;}.c1ltgu9w.youtube-video-container--hover:hover video{opacity:1;}.c1ltgu9w.youtube-video-container--hover .video-control-btn{display:none;}.c1ltgu9w .video-control-btn{overflow:hidden;-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);}.c1ltgu9w .video-control-btn svg{width:12px;height:14px;fill:var(--main-color);position:relative;-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out;}.c1ltgu9w .video-control-btn:before{content:'';display:block;background-color:var(--main-color);width:100%;height:100%;-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out;top:0;left:0;-webkit-transform:translateY(100%);-ms-transform:translateY(100%);transform:translateY(100%);z-index:0;position:absolute;}.c1ltgu9w .video-control-btn:hover:before{-webkit-transform:none;-ms-transform:none;transform:none;}.c1ltgu9w .video-control-btn:hover svg{fill:var(--main-color-text,#fff);}.contrast .c1ltgu9w .video-control-btn svg{fill:#000;}.contrast .c1ltgu9w .video-control-btn:before{background-color:#000;}.contrast .c1ltgu9w .video-control-btn:hover svg{fill:#fff;}.c1ltgu9w video::-webkit-media-controls-panel{display:none;opacity:0;}@media (min-width:768px){.c1ltgu9w .videoControlBtnOpacity{opacity:0;}.c1ltgu9w:hover .videoControlBtnOpacity{opacity:1;}}
.s131fr9h{background:#fff;width:100%;height:100%;object-fit:cover;}@media (min-width:768px){.s131fr9h{object-fit:none;}}
.s1sfttgl{background:#fff;width:100%;height:100%;object-fit:cover;}
.lxqb5mp{position:absolute;right:25px;margin-right:0 !important;}@media (min-width:768px){.lxqb5mp{right:69px;}}.lxqb5mp img{width:12px;height:14px;}.lxqb5mp .icon-play__pause{display:none;}.lxqb5mp .icon-play--playing .icon-play__play{display:none;}.lxqb5mp .icon-play--playing .icon-play__pause{display:inline-block;}
.rpnd126{position:absolute;left:25px;margin-left:0 !important;}@media (min-width:768px){.rpnd126{left:40px;}}.rpnd126 img{width:12px;height:14px;}.rpnd126 .icon-play__pause{display:none;}.rpnd126 .icon-play--playing .icon-play__play{display:none;}.rpnd126 .icon-play--playing .icon-play__pause{display:inline-block;}
.t1t42cp9{top:25px;}@media (min-width:768px){.t1t42cp9{top:45px;}}
.l3rrbim{bottom:25px;left:25px;}@media (min-width:768px){.l3rrbim{left:40px;bottom:45px;}}
.l1y68du6{top:20px;left:20px;right:20px;}@media (min-width:768px){.l1y68du6{left:40px;top:40px;right:40px;}}
.r1exypts{bottom:20px !important;left:auto;right:20px;}@media (min-width:768px){.r1exypts{left:auto;top:31px;right:31px;}}
.r1k77vfo{bottom:20px !important;left:20px;right:auto;}@media (min-width:768px){.r1k77vfo{left:31px;top:31px;right:auto;}}
.bz7l6hg{bottom:45px;}
.c1iytxdh{bottom:20px;right:20px;}@media (min-width:768px){.c1iytxdh{top:calc(50% - 14.5px);left:calc(50% - 14.5px);}}
.chae43a{bottom:20px;left:20px;}@media (min-width:768px){.chae43a{top:calc(50% - 14.5px);right:calc(50% - 29px);}}
